/*
————————————————————————————————————
  GENERAL
————————————————————————————————————
*/
.slideshow{
  height: 100%;
  overflow: hidden;
}
.swiper-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s linear;
  &.hide {
    opacity: 0;
    transition: opacity 0.3s linear;
  }
}

.swiper-wrapper {
  height: 100%;
  width: 100%;
}

.swiper-slide{
  box-sizing: border-box;
  height: 100% !important;
  background-color: white;
  picture {
    display: block;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  .landscape img, .square img{
    width: 100%;
    height: auto;
    @include mq(md){
      height: 100%;
    }
  }

}

/*
————————————————————————————————————
  BUTTONS
————————————————————————————————————
*/

.swiper-button {
  position: absolute;
  height: 100%;
  cursor: pointer;
  width: 50%;
  z-index: 2;

  &.swiper-prev {
    left: 0;
    width: 50%;
  }

  &.swiper-next {
    right: 0;
    width: 50%;
  }

}


/*
————————————————————————————————————
  HOME CAROUSEL
————————————————————————————————————
*/

.nested-carousel {
  display: flex;
}

.swiper-container-left {
  width: 100%;
  height: 50%;
  overflow: hidden;
  @include mq(sm) {
    width: 50%;
  }
}

.swiper-container-right {
  width: 50%;
  height: 50%;
  overflow: hidden;
  display: none;
  @include mq(sm) {
    display: block;
  }
}


.menu-slide {
  display: grid;
  grid-template-areas: "a";
  grid-auto-rows: 80% 20%;
  @include mq(sm) {
    grid-template-areas: "a a";
    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: 100%;
  }
}

.projects-list .list-item {
  @include mq(sm) {
    padding-left: calc(50% + 0.5em);
  }
}
