.logo {
  z-index: 999;
  width: 100%;
  background: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md){
    padding: 1.875rem;
  }
  .alert{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5.75rem;
    @include mq(md){
      left: 1.875rem;
      top: unset;
      transform: unset;
    }
  }
  img {
    height: 55px;
  }
}

.footer {
  z-index: 999;
  bottom: 0;
  width: 100%;
  background-color: white;
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1.875rem;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;

  div {
    &[data-align="right"] {
      text-align: right;
    }

    &[data-align="left"] {
      text-align: left;
    }

    &[data-align="center"] {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: end;
      order: -1;
      grid-column: span 2;
      margin-bottom: 1rem;
      @include mq(md){
        order: unset;
        grid-column: unset;
      }
      a {
        margin: 0 0.5em;
      }
    }
  }
}

#about-wrapper{
  position: fixed;
  z-index: 99;
  top:0;
  left:0;
  opacity: 0;
  visibility: hidden;
  background-color: white;
  width: 100%;
  height: 100%;
  &.show{
    opacity: 1;
    visibility: visible;
  }
  .text-container{
    @include mq(md){
      padding: 6.5rem 30% 9rem
    }
    padding: 4.8rem 1rem 6rem;
    height: 100%;
    overflow: scroll;
    .lang-menu{
      position: sticky;
      top:0;
      background: white;
      display: flex;
      padding: 1em 0;
      justify-content: center;
      & > a{
        margin: 0 0.5em;
      }
    }
    .text.hide{
      display: none;
    }
    p{
      margin-bottom: 1em;
    }
    .info{
      margin-top: 4em;
      columns: 2;
      font-size: 0.75rem;
      @include mq(md){
        font-size: 0.75rem;
      }
      line-height: 1.15em;
      h3{
        font-size: 0.75rem;
        @include mq(md){
          font-size: 0.75rem;
        }
        font-weight: normal;
        text-align: center;
        margin-bottom: 1em;
      }
      p{
        break-inside: avoid;
      }
    }
  }
}