.loading-overlay{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    padding-bottom: 1rem;
  }
  &.hide{
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-duration-default $transition-timing-default 2s;
  }
}
$cur: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='25' height='25' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Crect x='0' class='st0' width='32' height='32'/%3E%3C/svg%3E%0A") 25 25, auto;

body[data-template='home']{
  cursor: $cur;
  a{
    cursor: $cur;
  }
  .swiper-button{
    cursor: $cur;
  }
}

body[data-template="home"] {
  header {
    position: fixed;
    width: 100%;
  }

  header .menu-collapsible{
    border: none;
  }

  header .menu-inner .main-menu .menu-item:first-of-type{
    border-top: 1px solid;
    @include mq(md){
      border-top: none;
    }
  };

  main {
    display: grid;
    grid-column-gap: 1.25rem;
    grid-template-columns: 1fr;
    padding: var(--hh) 0.75rem;
    @include mq(md){
      padding: 0;
      grid-template-columns: repeat(2, 50%);
    }
  }
}

.site-logo-container {
  padding: 1.375rem 1.375rem 0.7rem;
  position: absolute;
  bottom: 0;
  z-index: 9;
  left: 0;

  img {
    width: 12.5rem;
  }
}

.slideshow-legend {
  padding-top: calc(var(--hh) + 1rem);
  padding-right: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;
  padding-bottom: 0.85rem;

  .slide-legend, .slide-index {
    grid-column: 1/1;
  }

  .slide-index{
    position: fixed;
    z-index: 99;
    top: 0;
    left:0;
    padding: 0.35rem 0.75rem;
    &.hide{
      display: none;
    }
    @include mq(md){
      padding: 0;
      position: relative;
      top: unset;
      left: unset;
      &.hide{
        display: block;
      }
    }
  }

  .slide-legend {
    align-self: end;
    display: none;
    @include mq(md){
      display: block;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.categories-display {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99;
  padding-top: var(--hh);
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: 100%;
  grid-template-columns: repeat(2, 1fr);
  @include mq(md){
    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.category-cover {
  padding: 1.25rem;
  max-height: 100%;
}

.categories-list {
  margin-top: 1rem;
  padding-left: 1.25rem;
  display: grid;
  padding-right: 1.25rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;
  border-left: 1px solid;
  max-height: 100%;
  grid-template-rows: 100%;
  .category-wrap {
    border-right: 1px solid;
    min-width: 33.333%;
    padding-right: 1.25rem;
    max-height: 100%;
    &:last-of-type {
      border-right: none;
    }
    .category-wrap-inner {
      opacity: 0;
      pointer-events: none;
      height: 100%;
      overflow-y: scroll;
    }
    &.show .category-wrap-inner {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.cat-project-item{
  margin-bottom: 1em;
  cursor: pointer;
  a:hover, a.active{
    text-decoration: none;
    .title{
      @extend .txt-underline;
    }
  }
}