body[data-template='contact']{
  background-color: $color-blue;
  .menu-collapsible{
    border-bottom: 1px solid !important;
  };
  .menu-inner{
    border-top: none;
  }
}

.content-wrapper{
  flex: 1;
  height: 50%;
 display: grid;
  position: relative;
  grid-template-rows: min-content min-content 1fr;
  @include mq(md){
    grid-template-rows: auto;
  }
}


.contact-info{
  padding-top: 1.25rem;
  text-align: left;
  p span{
    display: none;
    @include mq(md){
      display: inline;
    }
  }
  p:not(.no-inl-md), a:not(.no-inl-md){
    display: block;
  }
  a{
    display: block;
    margin-top: 1em;
  }
  a.no-inl-md{
    display: block;
  }
  @include mq(md){
    text-align: center;
    p:not(.no-inl-md), a:not(.no-inl-md){
      display: inline;
    }
    a.no-inl-md{
      display: block;
    }
    a{
      margin-top: 0;
    }
  }
}

.portfolio{
  align-self: end;
  text-align: left;
  margin-top: 1em;
  @include mq(md){
    margin-top: 0;
    text-align: center;
  }
}

.copyrights{
  display: flex;
  align-items: end;
  @include mq(md){
    display: block;
    position: absolute;
    bottom:-0.1em;
    left:0;
  }
}