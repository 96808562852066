body[data-template='projects'] {
  background: white;
  @include mq(md){
    background: linear-gradient(90deg, white 50%, $color-blue 50%);
  }

  .header{
    border: none !important;
    @include mq(md){
      border-bottom: 1px solid !important;
    }
  }

  header .menu-collapsible{
    border: none;
  }

  main {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .content-wrapper {
    overflow-y: scroll;
    display: block;
  }

  .sub-menu {
    @extend .header;
    position: sticky !important;
    background: white !important;
    border-top: 1px solid;
    top: 0;
    grid-column: span 2;
    align-self: start;
    z-index: 9 !important;
    display: block;
    @include mq(md){
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 1.25rem;
      background: linear-gradient(90deg, white 50%, $color-blue 50%) !important;
    }

    & a:nth-of-type(4) {
      margin-left: 0.625rem;
    }

    .collapsible {
      @extend .row-padding;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;
      border-bottom: 1px solid;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      user-select: none;
      @include mq(md){
        padding: 0;
        grid-column-gap: 1.25rem;
        border-bottom: none;
        grid-template-columns: repeat(3, 1fr);
        margin-left: 0.625rem;
      }
      .current{
        display: none;
        @include mq(md){
          display: block;
          grid-column: span 2;
        }
      }
    }

    .collapsible .current{
      position: relative;
      &:before{
        position: absolute;
        left: -1.1em;
        bottom: 0.025em;
      }
    }
    .collapsible .current{
      &:before{
        content:"↓";
      }
    }
    .collapsible.active .current{
      &:before{
        content:"↑";
      }
    }

    .collapsible span{
      position: absolute;
      right: 0;
      bottom: 0.25em;
      @include mq(md){
        display: none;
      }
    }

    .collapsible span:before{
      content:"↓";
    }
    .collapsible.active span:before{
      content:"↑";
    }

    .content {
      width: 100%;
      padding-left: 50%;
      left: 0;
      background-color: white;
      @include mq(md){
        padding: 0 18px;
        background-color: $color-blue;
        left: 50%;
        top: calc(var(--hh) + 1px);
        width: 50%;
      }


      .content-inner {
        @include mq(md){
          height: calc(var(--vh) * 100);
        }

        li {
          position: relative;
          @extend .row-padding;
          padding-right: 0;
          padding-left: 0;
          border-bottom: 1px solid;

          a {
            display: block;
            @include mq(md){
              padding-left: calc(33.3333333333% + 1.25rem / 3);
            }
          }

          a.selected {
            &:before {
              content: "\00a0";
              right:0;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              margin: auto 0;
              height: 8px;
              width: 8px;
              aspect-ratio: 1/1;
              background-color: black;
              @include mq(md){
                left: calc(33.3333% - 1.25rem);
                right: unset;
              }
            }
          }
        }
      }

    }
  }

  .project-item {
    border-bottom: 1px solid;
    height: 16.05rem;
    @include mq(md){
      margin: 0 1.25rem;
    }
    margin: 0 0.75rem;


    a{
      text-decoration: none !important;
      @include mq(md){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2.5rem;
      }
    }
  }

  .project-item--images {
    display: none;
    @include mq(md){
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
    }

    .image-wrap {
      height: 16.05rem;
      padding: 1.25rem 0;
      max-height: 100%;
      overflow: hidden;
      aspect-ratio: 1/1;
    }

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      object-fit: contain;
      object-position: left center;
      filter: grayscale(100);
    }

    .square img {
      width: 80%;
      max-height: 100%;
      height: auto;
    }

    .portrait img {
      height: 100%;
      width: auto;
    }

    .landscape img {
      width: 100%;
      height: auto;
    }

  }

  .project-item--infos {
    @include mq(md){
      margin-top: 0.35rem;
      max-height: calc(16.05rem - 0.35rem);
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-items: start;
    overflow: hidden;
    .title-image-wrap{
      display: flex;
      flex-direction: column;
      grid-template-rows: min-content;
      height: calc(16.05rem - 0.35rem - 1.925rem);
      .cover-container{
        margin-top: 0.35rem;
        @include mq(md){
          display: none;
        }
        height: 50%;
        flex: 1;
        img{
          height: 100%;
          width: auto;
          object-fit: contain;
          object-position: top;
          filter: grayscale(100);
        }
      }
    }
  }
}

