body[data-template='about']{
  @include mq(md){
    background: linear-gradient(90deg, white 50%,$color-yellow 50%);
  }
  .content-wrapper{
    display: block;
    overflow-y: scroll;
    @include mq(md){
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-template-rows: min-content auto 1fr;
    }
  }
  main{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  header .menu-collapsible{
    border: none;
  }

  header .menu-inner .main-menu .menu-item:first-of-type{
    border-top: 1px solid;
    @include mq(md){
      border-top: none;
    }
  };

}

.sub-menu{
  @extend .header;
  position: sticky !important;
  border-top: 1px solid;
  background: linear-gradient(90deg, white 50%,$color-yellow 50%);
  top:0;
  grid-column: span 2;
  align-self: start;
  display: none;
  z-index: 99 !important;
  grid-template-columns: repeat(6,1fr);
  grid-column-gap: 1.25rem;
  & a:nth-of-type(4){
    margin-left: 0.625rem;
  }
  @include mq(md){
    display: grid;
  }
}

.section-image-container{
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  display: none;
  @include mq(md){
  display: block;
  }
  div{
    padding-top: 1.375rem;
    position: sticky;
    top: calc(var(--hh) + 2px);
    bottom: 0;
    height: 400px;
  }
}

.section-wrapper{
  align-self: stretch;
}

.scroll-section{
  padding-bottom: 15.25rem;
  border-bottom: 1px solid;
  padding-top: 1rem;
  .txt-markdown{
    padding: 0 0.75rem;
    @include mq(md){
      padding: 0 1.25rem;
    }
    h2:first-of-type{
      margin-top: 0;
    }
  }
}

.scroll-section[data-id="equipe"]{
  h2:not(:first-of-type){
    margin-bottom: 1em;
  }
  h2:last-of-type{
    margin-bottom: 0;
  }
}
.scroll-section[data-id="distinctions"]{
  h2:not(:first-of-type){
    margin-top: 1.65em;
  }
  border-bottom: none;
}

.references{
  padding: 0 0.75rem;
  position: relative;
  grid-column: span 2;
  background-color: white;
  @include mq(md){
    padding: 0 1.25rem;
  }
}