.header{
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0 0.75rem;
  background-color: var(--mobile-menu-color);
  @include mq(md){
    background-color: transparent;
    padding: 0.35rem 1.375rem;
    border-bottom: 1px solid;
  }
}

#home header{
  background-color: transparent;
}

.logo{
  @include font(logo);
}

.title-logo-xs{
  position: absolute;
  top: 0.35rem;
  display: block;
  &.hide{
    display: none;
  }
  @include mq(md){
    display: none;
  }
}

header .collapsible{
  display: block;
  text-align: right;
  padding: 0.35rem 0;
  border-bottom: 1px solid;
  @include mq(md){
    border: none;
    display: none;
    padding: 0;
  }
  &:before{
    content: "+";
  }
  &.active:before{
    content: "–";
  }
}

.menu-inner{
  height: calc(var(--vh) * 100 - var(--hh));
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 0.75rem;
  & > [data-col="3"]{
    @include mq(md){
      grid-column: span 1;
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-gap: 1.25rem;
      &:nth-of-type(odd){
      }
      &:nth-of-type(even){
      }
    }
  }
  @include mq(md){
    height: auto;
    width: auto;
    display: grid;
    border: none;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2.5rem;
    padding-bottom: 0;
  }
}


.menu-inner .title-logo{
  display: none;
  @include mq(md){
    display: block;
  }
}

.menu-inner .main-menu{
  flex: 1;
}

.menu-inner .mobile-footer-menu{
  display: block;
  @include mq(md){
    display: none;
  }
  .menu-item{
    border-bottom: none;
    padding-top: 0.2em;
    padding-bottom: 0;
  }
}



.menu-inner .category-menu{
  display: none;
  @include mq(md){
    display: grid;
  }
}

.menu.content{
  background-color: var(--mobile-menu-color);
  width: 100%;
  left: 0;
  @include mq(md){
    background-color: transparent;
    width: auto;
    left: unset;
  }
}

.menu-inner .menu-item{
  @extend .row-padding;
  border-bottom: 1px solid;
  padding-left: 0;
  padding-right: 0;
  @include mq(md){
    padding: 0;
    border-bottom: none;
  }
  a.active{
    text-decoration: none;
  }
}

.lang-menu{
  text-transform: capitalize;
  @include fs(main);
  @include mq(md){
    font-size: 0.875rem;
    position: absolute;
    right: 0;
    top: 0.3rem;
  }
  .languages{
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 0.5em;
    @include mq(md){
      grid-template-columns: repeat(2,1fr);
      grid-gap: 0.1rem;
    }


  }
}

.menu-item{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  white-space: nowrap;
  .content{
    padding: 0;
    position: relative;
    right: 0;
    background-color: var(--mobile-menu-color);
  }
  .content a{
    display: block;
    user-select: none;
    padding: 0.35rem 0;
    border-bottom: 1px solid;
    &:first-of-type{
      padding-top: 0;
    }
    &:last-of-type{
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  @include mq(md){
    .content{
      display: none;
    }
  }
}
