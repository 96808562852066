body[data-template="project"]{
  main{
    padding-bottom: 0;
  }
  .content-wrapper{
    overflow: scroll;
  }
}

.project-header{
  display: grid;
  @include mq(md){
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: calc(1.375rem * 2);
    align-items: start;
  }

}

.project-cover{
  padding-top: 1.25rem;
  order: 1;
  @include mq(md){
    order: unset;
  }
}

.project-info{
  margin-top: 0.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content;
  align-items: start;
  @include mq(md){
    margin-top: 0.35rem;
  }
  .collapsible{
    grid-column: span 2;
    padding-left: 50%;
    cursor: pointer;
    user-select: none;
    span:before{
      display: inline-block;
      content: '[+]';
      text-transform: uppercase;
    }
    &.active{
      span:before{
        display: inline-block;
        content: '[–]';
        text-transform: uppercase;
      }
    }
  }
  .collapsible-wrapper{
    position: relative;
    grid-column: span 2;
  }
  .content{
    position: relative;
    background-color: transparent;
    .content-inner{
      padding-left: 50%;
      padding-top: 0.5em;
    }
  }
  .info-row{
    display: grid;
    line-height: 0.8em;
    border-bottom: 1px solid;
    grid-template-columns: 3.5rem 1fr;
    grid-column-gap: 1rem;
    &:first-of-type{
      border-top: 1px solid;
    }
  }
  .project-text{
    display: none;
    @include mq(md){
      display: block;
    }
    grid-column: span 2;
    align-self: end;
  }
}

.project-text-xs{
  @include mq(md){
    display: none;
  }
}


.gallery-container{
  section.grid{
    display: grid;
    margin: 5rem 0;
    grid-template-columns: 1fr;
    grid-column-gap: 2.5rem;
    grid-row-gap: 5rem;
    @include mq(md){
      margin: calc(1.375rem * 2) 0;
      grid-template-columns: repeat(2,1fr);
      grid-row-gap: 0;
    }
  }
  .column:nth-of-type(even){
    padding-left: 0;
    @include mq(md){
      padding-left: 1.375rem;
    }
  }
  .column[style='--columns:6']{
    grid-column: span 1;
  }
  .column[style='--columns:12']{
    @include mq(md){
      grid-column: span 2;
      .portrait, .square{
        padding: 0 30%;
      }
      .landscape{
        padding: 0 calc(25% + 1.375rem / 2);
      }
    }
  }
  picture, img{
    display: block;
  }

  .portrait, .square{
    padding: 0%;
  }
  .portrait.small, .square.small{
    padding: 0 10%;
  }
  .landscape{
    padding: 0;
  }
  .landscape.small{
    padding: 0 10%;
  }

  @include mq(md){
    .portrait, .square{
      padding: 0 15%;
    }
    .portrait.small, .square.small{
      padding: 0 21%;
    }
    .landscape{
      padding: 0;
    }
    .landscape.small{
      padding: 0 16%;
    }
  }

}