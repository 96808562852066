*{
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar{
  display: none;
}

html, body{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

main{
  height: 100%;
  padding-top: var(--hh);
  display: flex;
  flex-direction: column;
}

body:not([data-template="home"]) main{
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  @include mq(md){
    padding-bottom: 0.8rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}

body{
  letter-spacing: -0.01em;
}

a:hover{
  @include notouch{
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 1px;
  }

}

a.active{
  text-decoration: underline;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 1px;
}

.txt-underline{
  text-decoration: underline;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 1px;
}


.txt-xs{
  font-size: 0.875rem;
}

.txt-s{
  font-size: 1.25rem;
  line-height: 1.2em;
}

.row-padding{
  padding: 0.35rem 0.75rem;
  @include mq(md){
    padding: 0.35rem 1.375rem;
  }
}

.txt-markdown{
  h2{
    @include fs(main);
    margin-bottom: 1.65em;
    margin-top: 1rem;
  }
  h3, p{
    @extend .txt-s;
  }
  h3{
    padding-left: 2em;
    max-width: 50%;
    margin-top: 2.4em;
    margin-bottom: 1.2em;
  }
  p + p{
    text-indent: 2em;
  }
  ul{
    margin-top: 0.85em;
    li{
      margin-bottom: 0.85em;
    }
  }
  ul li p{
    text-indent: 1.7em;
    &:before{
      content: "\00a0";
      position: relative;
      left: -1.7em;
      top: 0.475em;
      display: inline-block;
      margin: auto 0;
      height: 0.3em;
      width: 0.3em;
      background-color: black;
    }
  }
}