.collapsible {
  grid-column: 4/7;
  cursor: pointer;
  user-select: none;
}

.active, .collapsible:hover {
}

.content {
  position: absolute;
  background-color: $color-blue;
  padding: 0 0.75rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s $transition-timing-default;
  @include mq(md) {
    padding: 0 18px;
  }
}


header .collapsible {
  display: block;
  @include mq(md) {
    display: none;
  }
}

header .content {
  padding: 0 0.75rem;
  @include mq(md) {
    padding: 0;
    position: relative;
    background-color: unset;
    max-height: unset;
    overflow: unset;
    transition: unset;
  }
}
